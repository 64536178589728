import { Box, Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import errorImg from 'assets/images/Error-img.svg';
import Link from 'components/Link';

const useStyles = makeStyles({
  errorWrap: {
    minHeight: 'calc(100vh - 177px)',
    display: 'flex',
    alignItems: 'center',
  },
  errorInfo: {
    '& h2': {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '1.5',
      color: '#022435',
      paddingBottom: '9px',
    },
    '& h3': {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: '28px',
      color: 'rgba(17, 17, 17, 0.7)',
      '& span': {
        color: '#04B078;',
      },
    },
  },
  errorImg: {
    display: 'block',
    paddingBottom: '23px',
    '& img': {
      width: '350px',
      minHeight: '245px',
    },
  },
});

export default function NotFound() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.errorWrap}>
        <Container>
          <Grid container textAlign="center">
            <Grid item xs={12}>
              <Box className={classes.errorInfo} textAlign="center">
                <div className={classes.errorImg}>
                  <img src={errorImg} alt="errorImage" />
                </div>
                <Typography variant="body1" component="h2">
                  Oops! Seems you lost your way.
                </Typography>
                <Typography variant="body1" component="h3">
                  Check{' '}
                  <Link href="/">
                    <span>www.podworld.in</span>
                  </Link>{' '}
                  to go in right direction.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
